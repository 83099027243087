/*
 * @Description:
 * @Author: XuAnjie
 * @Date: 2022-11-15 15:39:18
 * @LastEditors: Victor
 * @LastEditTime: 2023-06-09 15:03:27
 */
import en_energyStorageSys from './en-US/translation.json';
import zh_energyStorageSys from './zh-CN/translation.json';
import ja_energyStorageSys from './ja-JP/translation.json';
import de_energyStorageSys from './de-DE/translation.json';
import nl_energyStorageSys from './nl-NL/translation.json';
import it_energyStorageSys from './it-IT/translation.json';
import sp_energyStorageSys from './sp-SP/translation.json';
import el_energyStorageSys from './el-GR/translation.json';
import fr_energyStorageSys from './fr-FR/translation.json';
export const energyStorageSys = {
  en_energyStorageSys,
  zh_energyStorageSys,
  ja_energyStorageSys,
  de_energyStorageSys,
  nl_energyStorageSys,
  it_energyStorageSys,
  sp_energyStorageSys,
  fr_energyStorageSys,
  el_energyStorageSys,
};
