/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2022-11-16 13:24:33
 */
import en_informationCenter from './en-US/translation.json';
import zh_informationCenter from './zh-CN/translation.json';
import ja_informationCenter from './ja-JP/translation.json';
import de_informationCenter from './de-DE/translation.json';
import nl_informationCenter from './nl-NL/translation.json';
import it_informationCenter from './it-IT/translation.json';
import sp_informationCenter from './sp-SP/translation.json';
import fr_informationCenter from './fr-FR/translation.json';
import el_informationCenter from './el-GR/translation.json';
export const informationCenter = {
  en_informationCenter,
  zh_informationCenter,
  ja_informationCenter,
  de_informationCenter,
  nl_informationCenter,
  it_informationCenter,
  sp_informationCenter,
  fr_informationCenter,
  el_informationCenter,
};
