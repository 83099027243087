/*
 * @Author: Summer.Chen
 * @Date:   2022-11-08 13:09:34
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2023-03-28 15:51:39
 */
import en_index from './en-US/translation.json';
import zh_index from './zh-CN/translation.json';
import ja_index from './ja-JP/translation.json';
import de_index from './de-DE/translation.json';
import nl_index from './nl-NL/translation.json';
import it_index from './it-IT/translation.json';
import sp_index from './sp-SP/translation.json';
import el_index from './el-GR/translation.json';
import fr_index from './fr-FR/translation.json';
export const langIndex = { en_index, zh_index, ja_index, de_index, nl_index, it_index, sp_index, el_index, fr_index };
