/*
 * @Description:
 * @Author: Victor
 * @Date: 2023-03-29 20:04:16
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2024/05/10 10:18:39
 */
const zh = require('./lang/zh-CN/translation.json');
const en = require('./lang/en-US/translation.json');
const de = require('./lang/de-DE/translation.json');
const ja = require('./lang/ja-JP/translation.json');
const it = require('./lang/it-IT/translation.json');
const nl = require('./lang/nl-NL/translation.json');
const sp = require('./lang/sp-SP/translation.json');
const el = require('./lang/el-GR/translation.json');
const fr = require('./lang/fr-FR/translation.json');
export const energyStorageLang = { zh, en, de, ja, it, nl, sp, el, fr };
