/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: April
 * @Date: 2023/02/16 13:41:34
 * @LastEditTime: 2024-04-18 17:23:33
 */
import en_energyStorageSystemMaintenance from './en-US/translation.json';
import zh_energyStorageSystemMaintenance from './zh-CN/translation.json';
import ja_energyStorageSystemMaintenance from './ja-JP/translation.json';
import de_energyStorageSystemMaintenance from './de-DE/translation.json';
import nl_energyStorageSystemMaintenance from './nl-NL/translation.json';
import it_energyStorageSystemMaintenance from './it-IT/translation.json';
import sp_energyStorageSystemMaintenance from './sp-SP/translation.json';
import el_energyStorageSystemMaintenance from './el-GR/translation.json';
import fr_energyStorageSystemMaintenance from './fr-FR/translation.json';
export const energyStorageSystemMaintenance = {
  en_energyStorageSystemMaintenance,
  zh_energyStorageSystemMaintenance,
  ja_energyStorageSystemMaintenance,
  de_energyStorageSystemMaintenance,
  nl_energyStorageSystemMaintenance,
  it_energyStorageSystemMaintenance,
  sp_energyStorageSystemMaintenance,
  fr_energyStorageSystemMaintenance,
  el_energyStorageSystemMaintenance,
};
